// require('./bootstrap');
// window.Sortable = require('sortablejs/Sortable.min');
// window.fancybox = require('@fancyapps/ui/dist/fancybox.umd');

// import 'bootstrap';
import * as bootstrap from 'bootstrap';
import * as Sortable from "sortablejs";
import * as fancy from '@fancyapps/ui'
import { toSlug, addGoodToViewed, viewed_goods } from "./custom";


window.bootstrap = bootstrap;
window.Sortable = Sortable.default || Sortable;
window.fancybox = fancy;
window.toSlug = toSlug;
window.addGoodToViewed = addGoodToViewed;
window.viewed_goods = viewed_goods;


