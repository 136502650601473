export function translit(str)
{
    var ru=("А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я").split("-")
    var en=("A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya").split("-")
    var res = '';
    for(var i=0, l=str.length; i<l; i++)
    {
        var s = str.charAt(i), n = ru.indexOf(s);
        if(n >= 0) { res += en[n]; }
        else { res += s; }
    }
    return res;
}

export function toSlug(str) {
    let url = str.replace(/[\s]+/gi, '-');
    url = translit(url);
    url = url.replace(/[^0-9a-z_\-]+/gi, '').toLowerCase();
    return url;
}

export function addGoodToViewed(good){
    let viewed_goods = JSON.parse(localStorage.getItem('viewed-goods'));
    if(!viewed_goods){
        viewed_goods = [];
    }

    if(viewed_goods.map(e => e.id).indexOf(good.id) === -1) {
        if(viewed_goods.length >= 4) {
            viewed_goods.pop();
        }
        viewed_goods.unshift(good);
    }
    localStorage.setItem('viewed-goods', JSON.stringify(viewed_goods));
    return viewed_goods;
}

export function viewed_goods(id){
    let el = document.getElementById(id);
    const goods = JSON.parse(localStorage.getItem('viewed-goods'));
    let str = '';
    goods.forEach(function (good){
        str += '<div class="col-sm-6 col-md-3 mt-4 text-center fl-good">';
        str += '<a href="/good/'+good.slug+'"><img src="'+good.thumb+'" alt="photo" class="img-fluid ">';
        str += '<div class="mt-3 color1">'+good.name+'</div>';
        str += '<div class="mt-1 fl-price color2">'+good.price+' ₽</div></a></div>'
    });
    el.innerHTML = str;
}



